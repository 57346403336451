import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, Text, View, Image, Pressable } from 'react-native';
import { DrawerActions } from '@react-navigation/native';
import SettingBlue from '../../Assets/Images/settingBlue.png';
import LogoutBlue from '../../Assets/Images/logoutBlue.png';
import { useTheme } from "../../Contexts/ThemeProvider";
import axios from 'axios';
import { BASE_URL } from '../../Config';
import { useIsFocused } from "@react-navigation/native";
import bellBlueIcon from '../../Assets/Images/bellBlueIcon.png';
import passwordBlueIcon from '../../Assets/Images/passwordBlueIcon.png';
import reportBlueIcon from '../../Assets/Images/reportBlueIcon.png';
import moneyIcon from '../../Assets/Images/money.png';
import { useDispatch } from 'react-redux';
import { resetAuth } from '../../Actions/AuthActions';
import { Audio } from 'expo-av';
import AsyncStorage from '@react-native-async-storage/async-storage';

const DrawerContent = (props) => {
    const { dark, colors } = useTheme();
    const userData = useRef()
    const currentPosition = useRef('')
    const isFocused = useIsFocused();
    const dispatch = useDispatch()
    const [checkSoundAlert, setCheckSoundAlert] = useState(false)

    useEffect(() => {
        getUserData()
        checkOtherOptions()
    }, [new Date()])

    useEffect(() => {
        Audio.setAudioModeAsync({
            staysActiveInBackground: true,
            playsInSilentModeIOS: true,
        });
    }, []);

    const getUserData = () => {
        axios.get(`${BASE_URL}/me`)
            .then((res) => {
                userData.current = res.data
                getCurrentPosition(res.data.id)
            })
            .catch((e) => {
                if (e.response.status == 401) {
                    dispatch(resetAuth())
                }
            })
    }

    const checkOtherOptions = async () => {
        await AsyncStorage.getItem('SOUND')
            .then(req => JSON.parse(req))
            .then(data => {
                if (data != null) {
                    setCheckSoundAlert(data)
                } else {
                }
            })
            .catch(error => {
            });
    }

    const getCurrentPosition = (id) => {
        axios.get(`${BASE_URL}/get-user-m2m?id=${id}`)
            .then((res) => {
                currentPosition.current = res.data
            })
            .catch((e) => {
                if (e.response.status == 401) {
                    dispatch(resetAuth())
                }
            })
    }

    const onLogout = async () => {
        props.navigation.dispatch(DrawerActions.closeDrawer())
        dispatch(resetAuth())
        if (checkSoundAlert) {
            onPlaySound()
        }
    }

    const onPlaySound = async () => {
        const { sound } = await Audio.Sound.createAsync(
            require('../../Assets/sound/login_sound.mp3'),
            undefined,
            null,
            false
        );
        await sound.playAsync();
        setTimeout(() => {
            sound.unloadAsync();
        }, 10000)
    }

    return (
        <>
            <View style={[styles.container, { backgroundColor: '#c97fc7' }]}>
                <View style={[styles.topView, { borderColor: colors.Secondary }]}>
                    <View style={{ backgroundColor: '#8d448b', marginBottom: 5, flexDirection: 'row', justifyContent: 'center', padding: 5 }}>
                        <Text style={[styles.name, { color: colors.Primary, }]}>{userData.current?.name}</Text>
                        <Text style={[styles.code, { color: colors.Primary, }]}>({userData.current?.code})</Text>
                    </View>
                    <View style={styles.amountView}>
                        <View style={styles.amountSubView}>
                            <Text style={{ color: colors.Secondary, fontSize: 14, textTransform: 'uppercase' }}>Credited Points:</Text>
                            <Text style={[styles.amount, { color: colors.Secondary, fontSize: 14 }]}>{userData.current?.ledger_balance && userData.current?.ledger_balance.toFixed(2)}</Text>
                        </View>
                        <View style={styles.amountSubView}>
                            <Text style={{ color: colors.Secondary, fontSize: 14, textTransform: 'uppercase' }}>M2M Points:</Text>
                            <Text style={[styles.amount, { color: colors.Secondary, fontSize: 14 }]}>{currentPosition.current && currentPosition.current.toFixed(2)}</Text>
                        </View>
                        <View style={[styles.amountSubView, { marginBottom: 5 }]}>
                            <Text style={{ color: colors.Secondary, textTransform: 'uppercase', fontWeight: 'bold', fontSize: 15 }}>Net Points:</Text>
                            <Text style={[styles.netAmount, { color: colors.Secondary, }]}>{(Number(currentPosition.current) + Number(userData.current?.ledger_balance)).toFixed(2)}</Text>
                        </View>
                    </View>
                </View>
                <Pressable style={[styles.item, { borderColor: colors.Secondary }]} onPress={() => {
                    props.navigation.dispatch(DrawerActions.closeDrawer())
                    props.navigation.navigate('LedgerReport')
                }}>
                    <Image source={dark ? reportBlueIcon : reportBlueIcon} style={styles.icon} />
                    <Text style={[styles.title, { color: colors.Secondary }]}>Ledger Report</Text>
                </Pressable>
                <Pressable style={[styles.item, { borderColor: colors.Secondary }]} onPress={() => {
                    props.navigation.dispatch(DrawerActions.closeDrawer())
                    props.navigation.navigate('RejectionReport')
                }}>
                    <Image source={dark ? reportBlueIcon : reportBlueIcon} style={styles.icon} />
                    <Text style={[styles.title, { color: colors.Secondary }]}>Rejection Report</Text>
                </Pressable>
                <Pressable style={[styles.item, { borderColor: colors.Secondary }]} onPress={() => {
                    props.navigation.dispatch(DrawerActions.closeDrawer())
                    props.navigation.navigate('QuantityReport')
                }}>
                    <Image source={dark ? reportBlueIcon : reportBlueIcon} style={styles.icon} />
                    <Text style={[styles.title, { color: colors.Secondary }]}>Quantity Setting</Text>
                </Pressable>
                <Pressable style={[styles.item, { borderColor: colors.Secondary }]} onPress={() => {
                    props.navigation.dispatch(DrawerActions.closeDrawer())
                    props.navigation.navigate('ChangePassword')
                }}>
                    <Image source={dark ? passwordBlueIcon : passwordBlueIcon} style={styles.icon} />
                    <Text style={[styles.title, { color: colors.Secondary }]}>Change Password</Text>
                </Pressable>
                <Pressable style={[styles.item, { borderColor: colors.Secondary }]} onPress={() => {
                    props.navigation.dispatch(DrawerActions.closeDrawer())
                    props.navigation.navigate('Notification')
                }}>
                    <Image source={dark ? bellBlueIcon : bellBlueIcon} style={styles.icon} />
                    <View style={{ justifyContent: 'space-between', flexDirection: 'row', width: '90%' }}>
                        <Text style={[styles.title, { color: colors.Secondary }]}>Notifications</Text>
                        {
                            userData?.current?.count != 0 &&
                            <View style={{ backgroundColor: 'red', height: 25, width: 25, borderRadius: 25 / 2, justifyContent: 'center', alignItems: 'center' }}>
                                <Text style={{ color: 'white' }}>{userData?.current?.count}</Text>
                            </View>
                        }
                    </View>
                </Pressable>
                <Pressable style={[styles.item, { borderColor: colors.Secondary }]} onPress={() => {
                    props.navigation.dispatch(DrawerActions.closeDrawer())
                    props.navigation.navigate('Setting')
                }}>
                    <Image source={dark ? SettingBlue : SettingBlue} style={styles.icon} />
                    <Text style={[styles.title, { color: colors.Secondary }]}>Settings</Text>
                </Pressable>
                {/* <Pressable style={[styles.item, { borderColor: colors.Secondary }]} onPress={() => {
                    props.navigation.dispatch(DrawerActions.closeDrawer())
                    props.navigation.navigate('Margin')
                }}>
                    <Image source={moneyIcon} style={styles.icon} />
                    <Text style={[styles.title, { color: colors.Secondary }]}>Margin</Text>
                </Pressable> */}
                <Pressable style={[styles.item, { borderColor: colors.Secondary }]} onPress={() => onLogout()}>
                    <Image source={dark ? LogoutBlue : LogoutBlue} style={styles.icon} />
                    <Text style={[styles.title, { color: colors.Secondary }]}>Logout</Text>
                </Pressable>
            </View>
        </>
    )
}

export default DrawerContent;

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    topView: {
        marginTop: 15,
        borderBottomWidth: 1,
    },
    name: {
        fontSize: 20,
        marginLeft: 22,
        textAlign: 'center'
    },
    code: {
        fontSize: 20,
        marginLeft: 5,
        textAlign: 'center'
    },
    amountView: {
        justifyContent: 'flex-end',
        alignSelf: 'flex-end',
        marginRight: 20
    },
    amountSubView: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    netProfitView: {
        flexDirection: 'row',
        borderRadius: 5,
        marginTop: 10,
        padding: 5,
        justifyContent: 'space-between',
        marginBottom: 20
    },
    amount: {
        fontWeight: 'bold',
        marginLeft: 10,
    },
    netAmount: {
        fontWeight: 'bold',
        marginLeft: 10,
    },
    item: {
        flexDirection: 'row',
        padding: 15,
        borderBottomWidth: 1,
    },
    title: {
        fontSize: 16,
        marginLeft: 15
    },
    icon: {
        height: 20,
        width: 20
    }
})