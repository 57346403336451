import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, View, Text, Pressable, FlatList, ActivityIndicator, TextInput, Keyboard } from 'react-native';
import { DrawerActions } from '@react-navigation/native';
import { Height, Width } from '../../Utility/Utility';
import MenuWhiteIcon from '../../Assets/Images/menu-white.png';
import MenuBlackIcon from '../../Assets/Images/menu-black.png';
import UpIcon from '../../Assets/Images/up.png';
import DownIcon from '../../Assets/Images/down.png';
import SearchIcon from '../../Assets/Images/search-icon-white.png';
import SearchBlackIcon from '../../Assets/Images/searchBlue.png';
import { setAuthToken } from '../../Utility/SetHeader';
import { useSelector } from 'react-redux';
import addIcon from '../../Assets/Images/add-white-icon.png';
import addBlackIcon from '../../Assets/Images/addBlue.png';
import editBlack from '../../Assets/Images/editBlue.png';
import editWhite from '../../Assets/Images/editWhite.png';
import crossIcon from '../../Assets/Images/cross-black.png';
import axios from 'axios';
import { BASE_URL } from '../../Config';
import moment from 'moment';
import RBSheet from "react-native-raw-bottom-sheet";
import AsyncStorage from '@react-native-async-storage/async-storage';
import TextTicker from 'react-native-text-ticker';
import { useTheme } from '../../Contexts/ThemeProvider';
import Checkbox from 'expo-checkbox';
import Toast from 'react-native-toast-message';
import { Audio } from 'expo-av';
import cancelIcon from '../../Assets/Images/cancel-blue.png';
import deleteIcon from '../../Assets/Images/delete-blue.png';
import { Image } from 'expo-image';
import Modal from 'modal-react-native-web';
import webstomp from "webstomp-client";
import { useNavigation } from '@react-navigation/native';
import Spinner from 'react-native-loading-spinner-overlay';
import { RefreshControl } from 'react-native-web-refresh-control'
import { resetAuth } from '../../Actions/AuthActions';
import { useDispatch } from 'react-redux';

const MarketWatch = (props) => {
    const dispatch = useDispatch()
    const { dark, colors } = useTheme();
    const [selectedSegment, setSelectedSegment] = useState('NFO')
    const auth = useSelector(state => state.auth)
    const showWebView = useRef(false)
    const [modalVisible, setModalVisible] = useState(false);
    const [liveData, setLiveData] = useState([])
    const [isLoading, setLoading] = useState(false)
    const refRBSheet = useRef();
    const selectedIndex = useRef();
    const [lot, setLot] = useState('1')
    const [quantity, setQuantity] = useState()
    const [marketType, setMarketType] = useState('MARKET')
    const [price, setPrice] = useState('')
    const [userDetail, setUserDetail] = useState()
    const [buttonLoader, setButtonLoader] = useState(false)
    const [topMessage, setTopMessage] = useState('')
    const [showSearch, setShowSearch] = useState(false)
    const [searchText, setSearchText] = useState('')
    const selectedTrade = useRef('');
    const userId = useRef('')
    const [isEdit, setIsEdit] = useState(false);
    const [checkShowModal, setCheckShowModal] = useState(false)
    const [checkSoundAlert, setCheckSoundAlert] = useState(false)
    const niftyPosition = useRef();
    const niftyPoints = useRef();
    const sensexPosition = useRef();
    const sensexPoints = useRef();
    const live = useRef([]);
    const client = useRef();
    const [isFocused, setIsFocused] = useState(false);
    const navigation = useNavigation();
    const [isSpinner, setIsSpinner] = useState(false)


    const _onMenuPress = () => {
        showWebView.current = true
        props.navigation.dispatch(DrawerActions.openDrawer());
    }

    useEffect(() => {
        let isCancelled = false;
        if (!isCancelled) {
            if (auth.access_token) {
                showWebView.current = false
                setAuthToken(auth.access_token)
                getUserDetail()
                checkTab()
                checkOtherOptions()
            }
        }
        return () => {
            isCancelled = true
        }
    }, [isFocused])

    useEffect(() => {
        Audio.setAudioModeAsync({
            staysActiveInBackground: true,
            playsInSilentModeIOS: true,
        });
    }, []);

    useEffect(() => {

        setInterval(() => {
            if (!client.current || !client.current.connected) {
                connect();
            }
        }, 3000);


        const unsubscribeFocus = navigation.addListener('focus', () => {
            setIsFocused(true);
        });
        const unsubscribeBlur = navigation.addListener('blur', () => {
            setIsFocused(false);
        });

        return () => {
            unsubscribeFocus();
            unsubscribeBlur();
        };
    }, []);



    useEffect(() => {
        connect();
    }, [isFocused])

    const connect = () => {
        var url = "wss://tradingfast.online:15673/ws";
        var login = "market";
        var password = "TradingFast";
        if (!client.current || !client.current.connected) {
            client.current = webstomp.client(url, { debug: false });
            client.current.connect(login, password, () => {
                client.current.subscribe("/exchange/market-data", (message) => {
                    onMessage(JSON.parse(message.body))
                });
            });
        }
    }

    const checkTab = async () => {
        await AsyncStorage.getItem('tab')
            .then(req => JSON.parse(req))
            .then(data => {
                if (data != null) {
                    setSelectedSegment(data.currenTab)
                    getData(data.currenTab)
                    getUserDetail()
                    getMessage()
                } else {
                    getData('NFO')
                    getUserDetail()
                    getMessage()
                }
            })
            .catch(error => {
                getData('NFO')
                getUserDetail()
                getMessage()
            });
    }

    const checkOtherOptions = async () => {
        await AsyncStorage.getItem('TRADE')
            .then(req => JSON.parse(req))
            .then(data => {
                if (data != null) {
                    setCheckShowModal(data)
                } else {
                }
            })
            .catch(error => {
            });
        await AsyncStorage.getItem('SOUND')
            .then(req => JSON.parse(req))
            .then(data => {
                if (data != null) {
                    setCheckSoundAlert(data)
                } else {
                }
            })
            .catch(error => {
            });
    }

    const getUserDetail = async () => {
        await AsyncStorage.getItem('user_detail')
            .then(req => JSON.parse(req))
            .then(data => {
                if (data != null) {
                    userId.current = data.id
                    setUserDetail(data)
                } else {
                }
            })
            .catch(error => {
            });
    }

    const getMessage = () => {
        axios.get(`${BASE_URL}/settings`)
            .then((res) => {
                setTopMessage(res.data.header_message)
            })
            .catch((e) => {
                if (e.response.status == 401) {
                    dispatch(resetAuth())
                }
            })
    }

    const getData = (value, search) => {
        setLoading(true)
        axios.get(`${BASE_URL}/wish-list-mobile?exchange=${value}&keyword=${search ? search : ''}`)
            .then((res) => {
                for (var i in res.data) {
                    res.data[i].isChecked = false;
                }
                live.current = res.data
                setLiveData(res.data)
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
                if (e.response.status == 401) {
                    dispatch(resetAuth())
                }
            })
    }

    var isRunning = false;
    const onMessage = (data) => {
        if (!isRunning) {
            if (live.current.length > 0 && data.length > 0) {
                for (var i in data) {
                    if (data[i].it == 265) {
                        sensexPosition.current = data[i].ltp;
                        sensexPoints.current = data[i].n;
                    }
                    if (data[i].it == 256265) {
                        niftyPosition.current = data[i].ltp;
                        niftyPoints.current = data[i].n;
                    }
                    for (var j in live.current) {
                        if (live.current[j].instrument_token == data[i].it) {
                            live.current[j].a = data[i].a.toFixed(2);
                            live.current[j].b = data[i].b.toFixed(2);
                            live.current[j].ac = data[i].ac;
                            live.current[j].bc = data[i].bc;
                            live.current[j].ltpc = data[i].ltpc;
                            live.current[j].c = data[i].c;
                            live.current[j].ltp = data[i].ltp.toFixed(2);
                            live.current[j].it = data[i].it;
                            live.current[j].ch = data[i].ch;
                            live.current[j].h = data[i].h.toFixed(2);
                            live.current[j].l = data[i].l.toFixed(2);
                            live.current[j].o = data[i].o;
                            live.current[j].n = data[i].n;
                            live.current[j].ul = data[i].ul;
                            live.current[j].ll = data[i].ll;
                            live.current[j].ba = data[i].ba;
                        }
                    }
                }

                setLiveData([...live.current])
            }

            isRunning = false;
        }

    }

    const onSubmit = (status) => {
        if (buttonLoader || isSpinner) {
            return;
        }
        setButtonLoader(true)
        setIsSpinner(true)
        setModalVisible(false)
        var priceValue = '';
        var reject_reason = '';
        if (status == 'BUY' && marketType == 'MARKET') {
            priceValue = liveData[selectedIndex.current].a
        } else if (status == 'SELL' && marketType == 'MARKET') {
            priceValue = liveData[selectedIndex.current].b
        } else if (marketType == 'LIMIT' || marketType == 'STOPLOSS') {
            if (price == 0 || price == '') {
                Toast.show({
                    type: 'error',
                    text1: `Price can't be zero(0).`,
                });
                return;
            }

            priceValue = price
        }

        // if (
        //     Number(price) < Number(liveData[selectedIndex.current].ll) &&
        //     marketType != "MARKET" && marketType != "INTRADAY"
        // ) {
        //     Toast.show({
        //         type: 'error',
        //         text1: `price should be greater then lower limit`,
        //         text2: `${liveData[selectedIndex.current].ll}. `,
        //         visibilityTime: 5000
        //     });
        //     var reject_reason =
        //         "price should be greater then lower limit " +
        //         liveData[selectedIndex.current].ll +
        //         ".";
        // }

        // if (Number(price) > Number(liveData[selectedIndex.current].ul) && marketType != "INTRADAY") {
        //     Toast.show({
        //         type: 'error',
        //         text1: `price should be less then upper limit`,
        //         text2: `${liveData[selectedIndex.current].ul}. `,
        //     });
        //     var reject_reason =
        //         "price should be less then upper limit " +
        //         liveData[selectedIndex.current].ul +
        //         ".";
        // }


        // if (
        //     status == "BUY" &&
        //     marketType == "STOPLOSS"
        // ) {
        //     if (
        //         price <= liveData[selectedIndex.current].ltp &&
        //         price <= liveData[selectedIndex.current].a
        //     ) {
        //         Toast.show({
        //             type: 'error',
        //             text1: `Stoploss price should be greater then ltp price.`,
        //         });
        //         var reject_reason =
        //             "Stoploss price should be greater then ltp price.";
        //     }
        // }

        // if (
        //     status == "SELL" &&
        //     marketType == "STOPLOSS"
        // ) {
        //     if (
        //         price >= liveData[selectedIndex.current].ltp &&
        //         price >= liveData[selectedIndex.current].b
        //     ) {
        //         Toast.show({
        //             type: 'error',
        //             text1: `Stoploss price should be less then ltp price.`,
        //         });
        //         var reject_reason =
        //             "Stoploss price should be less then ltp price.";
        //     }
        // }


        // if (marketType == "LIMIT") {
        //     if (
        //         price >= liveData[selectedIndex.current].b &&
        //         price <= liveData[selectedIndex.current].a
        //     ) {
        //         Toast.show({
        //             type: 'error',
        //             text1: `Limit price should not be in between bid price and ask price.`,
        //         });
        //         var reject_reason =
        //             "Limit price should not be in between bid price and ask price.";
        //     }
        // }

        // if (
        //     marketType == "LIMIT" &&
        //     status == "BUY"
        // ) {
        //     if (price > liveData[selectedIndex.current].ltp) {
        //         Toast.show({
        //             type: 'error',
        //             text1: `Limit price should be Less then LTP.`,
        //         });
        //         var reject_reason = "Limit price should be Less then LTP.";
        //     }
        // }


        // if (
        //     marketType == "LIMIT" &&
        //     status == "SELL"
        // ) {
        //     if (price < liveData[selectedIndex.current].ltp) {
        //         Toast.show({
        //             type: 'error',
        //             text1: `Limit price should be greater then LTP.`,
        //         });
        //         var reject_reason = "Limit price should be greater then LTP.";
        //     }
        // }

        if (marketType == 'LIMIT' || marketType == 'STOPLOSS') {
            var data = (priceValue / liveData[selectedIndex.current]?.tick_size).toFixed(2) % 1 == 0
            if (priceValue == '' || data == false) {
                if (priceValue == '') {
                    Toast.show({
                        type: 'error',
                        text1: "Please enter price.",
                    });
                } else if (data == false) {
                    Toast.show({
                        type: 'error',
                        text1: ` Price Should be in multiple of ${liveData[selectedIndex.current]?.tick_size}`,
                    });
                }

            } else {
                setButtonLoader(true)
                setIsSpinner(true)
                axios.post(`${BASE_URL}/add-trade`, {
                    a: liveData[selectedIndex.current].a,
                    ac: liveData[selectedIndex.current].ac,
                    b: liveData[selectedIndex.current].b,
                    bc: liveData[selectedIndex.current].bc,
                    c: liveData[selectedIndex.current].c,
                    ch: liveData[selectedIndex.current].ch,
                    device: 'MOBILE',
                    exchange: liveData[selectedIndex.current].exchange,
                    h: liveData[selectedIndex.current].h,
                    instrument_token: liveData[selectedIndex.current].instrument_token,
                    l: liveData[selectedIndex.current].l,
                    lot_size: lot,
                    ltp: liveData[selectedIndex.current].ltp,
                    ltpc: liveData[selectedIndex.current].ltpc,
                    n: liveData[selectedIndex.current].n,
                    name: liveData[selectedIndex.current].name,
                    o: liveData[selectedIndex.current].o,
                    order_action: status,
                    order_type: marketType,
                    price: priceValue,
                    quantity: quantity,
                    trading_symbol: liveData[selectedIndex.current].name + " " + moment(liveData[selectedIndex.current].expiry).format('DDMMM').toUpperCase(),
                    user_id: userDetail.id,
                    reject_reason: reject_reason ? reject_reason : '',
                    ba: liveData[selectedIndex.current].ba
                })
                    .then(async (res) => {
                        setIsSpinner(false)
                        console.log("res1", res.data)
                        setButtonLoader(false)
                        setModalVisible(false)
                        if (reject_reason) {
                            if (checkSoundAlert) {
                                onPlayErrorSound()
                            }
                        } else {
                            if (status == 'SELL') {
                                Toast.show({
                                    type: 'error',
                                    text1: marketType == 'INTRADAY' ? 'Intraday Close Limit Order Placed Sucessfully' : `${marketType == 'MARKET' ? 'Trade' : 'Order'} Placed Sucessfully`,
                                    text2: marketType != 'INTRADAY' && `${liveData[selectedIndex.current].name} ${liveData[selectedIndex.current].expiry && moment(liveData[selectedIndex.current].expiry).format('DDMMM').toUpperCase()} ${status} ${quantity} @ ${res?.data?.trade_obj?.price ? res?.data?.trade_obj?.price : priceValue}. `,

                                });
                            } else {
                                Toast.show({
                                    type: 'success',
                                    text1: marketType == 'INTRADAY' ? 'Intraday Close Limit Order Placed Sucessfully' : `${marketType == 'MARKET' ? 'Trade' : 'Order'} Placed Sucessfully`,
                                    text2: marketType != 'INTRADAY' && `${liveData[selectedIndex.current].name} ${liveData[selectedIndex.current].expiry && moment(liveData[selectedIndex.current].expiry).format('DDMMM').toUpperCase()} ${status} ${quantity} @ ${res?.data?.trade_obj?.price ? res?.data?.trade_obj?.price : priceValue}. `,

                                });
                            }

                            if (checkSoundAlert) {
                                onPlaySuccessSound()
                            }
                        }

                        setMarketType('MARKET')
                        setPrice('')
                        setQuantity('')
                        setLot('')
                        getData(selectedSegment)
                    })
                    .catch(async (e) => {
                        setIsSpinner(false)
                        setModalVisible(false)
                        setButtonLoader(false)
                        console.log("e?.response?.data?.message", e?.response?.data?.message)
                        Toast.show({
                            type: 'error',
                            text1: e?.response?.data?.message,
                        });
                        if (checkSoundAlert) {
                            onPlayErrorSound()
                        }
                        if (e.response.status == 401) {
                            dispatch(resetAuth())
                        }
                    })
            }
        } else {
            setButtonLoader(true)
            setIsSpinner(true)
            axios.post(`${BASE_URL}/add-trade`, {
                a: liveData[selectedIndex.current].a,
                ac: liveData[selectedIndex.current].ac,
                b: liveData[selectedIndex.current].b,
                bc: liveData[selectedIndex.current].bc,
                c: liveData[selectedIndex.current].c,
                ch: liveData[selectedIndex.current].ch,
                device: 'MOBILE',
                exchange: liveData[selectedIndex.current].exchange,
                h: liveData[selectedIndex.current].h,
                instrument_token: liveData[selectedIndex.current].instrument_token,
                l: liveData[selectedIndex.current].l,
                lot_size: lot,
                ltp: liveData[selectedIndex.current].ltp,
                ltpc: liveData[selectedIndex.current].ltpc,
                n: liveData[selectedIndex.current].n,
                name: liveData[selectedIndex.current].name,
                o: liveData[selectedIndex.current].o,
                order_action: status,
                order_type: marketType,
                price: priceValue,
                quantity: quantity,
                trading_symbol: liveData[selectedIndex.current].name + " " + moment(liveData[selectedIndex.current].expiry).format('DDMMM').toUpperCase(),
                user_id: userDetail.id,
                reject_reason: reject_reason ? reject_reason : '',
                ba: liveData[selectedIndex.current].ba
            })
                .then(async (res) => {
                    setIsSpinner(false)
                    refRBSheet.current.close()
                    setMarketType('MARKET')
                    setPrice('')
                    setQuantity('')
                    setLot('')
                    setButtonLoader(false)
                    setModalVisible(false)
                    if (reject_reason) {
                        if (checkSoundAlert) {
                            onPlayErrorSound()
                        }
                    } else {
                        if (status == 'SELL') {
                            Toast.show({
                                type: 'error',
                                text1: marketType == 'INTRADAY' ? 'Intraday Close Limit Order Placed Sucessfully' : `${marketType == 'MARKET' ? 'Trade' : 'Order'} Placed Sucessfully`,
                                text2: marketType != 'INTRADAY' && `${liveData[selectedIndex.current].name} ${liveData[selectedIndex.current].expiry && moment(liveData[selectedIndex.current].expiry).format('DDMMMYYYY').toUpperCase()} ${status} ${quantity} @ ${res?.data?.trade_obj?.price ? res?.data?.trade_obj?.price : priceValue}. `,
                            });
                        } else {
                            Toast.show({
                                type: 'success',
                                text1: marketType == 'INTRADAY' ? 'Intraday Close Limit Order Placed Sucessfully' : `${marketType == 'MARKET' ? 'Trade' : 'Order'} Placed Sucessfully`,
                                text2: marketType != 'INTRADAY' && `${liveData[selectedIndex.current].name} ${liveData[selectedIndex.current].expiry && moment(liveData[selectedIndex.current].expiry).format('DDMMMYYYY').toUpperCase()} ${status} ${quantity} @ ${res?.data?.trade_obj?.price ? res?.data?.trade_obj?.price : priceValue}. `,
                            });
                        }

                        if (checkSoundAlert) {
                            onPlaySuccessSound()
                        }
                    }
                    getData(selectedSegment)
                })
                .catch(async (e) => {
                    setIsSpinner(false)
                    setModalVisible(false)
                    setButtonLoader(false)
                    console.log("e?.response?.data?.message", e?.response?.data?.message)
                    Toast.show({
                        type: 'error',
                        text1: e?.response?.data?.message,
                    });
                    if (checkSoundAlert) {
                        onPlayErrorSound()
                    }
                    if (e.response.status == 401) {
                        dispatch(resetAuth())
                    }
                })
        }
    }

    const onChangingLot = (value) => {
        setLot(value)
        setQuantity((value * liveData[selectedIndex.current].lot_size).toString())
    }

    const onChangingQuantity = (value) => {
        setQuantity(value.replace(/[^0-9]/g, ''))
        var data = (1 / liveData[selectedIndex.current].lot_size * value)
        setLot(data.toFixed(2))
    }

    const onChangeTab = async (value) => {
        const data = JSON.stringify({ currenTab: value })
        await AsyncStorage.setItem('tab', data)
        setSelectedSegment(value)
        getData(value)
    }

    const onDeleteScript = () => {
        var array = [];
        for (var i in liveData) {
            if (liveData[i].isChecked == true) {
                array.push(liveData[i].id)
            }
        }
        setIsSpinner(true)
        axios.put(`${BASE_URL}/remove-from-wishlist-app`, {
            ids: array
        })
            .then((res) => {
                setIsEdit(false)
                setIsSpinner(false)
                checkTab()
            })
            .catch((e) => {
                setIsSpinner(false)
                if (e.response.status == 401) {
                    dispatch(resetAuth())
                }
            })
    }

    const onPlaySuccessSound = async () => {
        const { sound } = await Audio.Sound.createAsync(
            require('../../Assets/sound/login_sound.mp3'),
            undefined,
            null,
            false
        );
        await sound.playAsync();
        setTimeout(() => {
            sound.unloadAsync();
        }, 10000)
    }

    const onPlayErrorSound = async () => {
        const { sound } = await Audio.Sound.createAsync(
            require('../../Assets/sound/errorNew.mp3'),
            undefined,
            null,
            false
        );
        await sound.playAsync();
        setTimeout(() => {
            sound.unloadAsync();
        }, 10000)
    }

    return (
        <>
            <Spinner
                visible={isSpinner}
                textContent={'Loading...'}
                textStyle={styles.spinnerTextStyle}
            />
            <Modal animationType="slide" transparent={true} visible={modalVisible} ariaHideApp={false}>
                <View style={styles.centeredView}>
                    <View style={styles.modalView}>
                        <View >
                            <View style={{ backgroundColor: 'white', borderRadius: 20 }}>
                                <Text style={{ textAlign: 'center', fontSize: 15, color: colors.Secondary, fontWeight: '600', marginTop: 20 }}>Are you sure you want to place order.</Text>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                    <Pressable style={styles.cancelButton} disabled={isSpinner} onPress={() => {
                                        refRBSheet.current.open()
                                        setModalVisible(!modalVisible)
                                    }}>
                                        <Text style={styles.cancelText}>No</Text>
                                    </Pressable>
                                    <Pressable onPress={() => onSubmit(selectedTrade.current)} disabled={isSpinner} style={[styles.continueButton, { backgroundColor: selectedTrade.current == 'BUY' ? '#1034A6' : '#FF033E' }]}>
                                        <Text style={styles.continueText}>{marketType == 'INTRADAY' ? 'Yes' : selectedTrade.current}</Text>
                                    </Pressable>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
            </Modal>
            <View style={[styles.container, { backgroundColor: 'white' }]}>
                <View style={[styles.header, { borderColor: colors.Secondary }]}>
                    <Pressable onPress={() => _onMenuPress()}>
                        <Image source={dark ? MenuWhiteIcon : MenuBlackIcon} style={{ height: 25, width: 25 }} />
                    </Pressable>
                    {
                        showSearch == false &&
                        <View style={styles.headerIcon}>
                            <Pressable onPress={() => setIsEdit(!isEdit)}>
                                <Image source={dark ? editWhite : editBlack} style={styles.addIcon} />
                            </Pressable>
                            <Pressable onPress={() => {
                                showWebView.current = true
                                props.navigation.navigate('Scripts')
                            }}>
                                <Image source={dark ? addIcon : addBlackIcon} style={styles.addIcon} />
                            </Pressable>
                            <Pressable onPress={() => setShowSearch(true)}>
                                <Image source={dark ? SearchIcon : SearchBlackIcon} style={styles.searchIcon} />
                            </Pressable>
                        </View>
                    }
                    {
                        showSearch == true &&
                        <View style={styles.headerIcon}>
                            <TextInput style={[styles.input, { color: colors.Secondary, backgroundColor: colors.color2, marginBottom: 5 }]} placeholder='Search' placeholderTextColor={'#D3D3D3'} onChangeText={text => { setSearchText(text), getData(selectedSegment, text) }} value={searchText} />
                            <Pressable onPress={() => setShowSearch(false)}>
                                <Image source={crossIcon} style={{ height: 30, width: 30, marginTop: 5 }} />
                            </Pressable>
                        </View>
                    }
                </View>
                <View style={{ backgroundColor: '#c97fc7', paddingBottom: 5 }}>
                    <View style={styles.segmentTabView}>
                        <Pressable style={[styles.segementTab, { backgroundColor: selectedSegment == 'NFO' ? '#8d448b' : colors.Primary, borderColor: colors.Secondary }]} onPress={() => { onChangeTab('NFO') }}>
                            <Text style={[styles.tabText, { color: selectedSegment == 'NFO' ? 'white' : colors.Secondary }]}>NSE</Text>
                        </Pressable>
                        <Pressable style={[styles.segementTab, { backgroundColor: selectedSegment == 'MCX' ? '#8d448b' : colors.Primary, borderColor: colors.Secondary }]} onPress={() => { onChangeTab('MCX') }}>
                            <Text style={[styles.tabText, { color: selectedSegment == 'MCX' ? 'white' : colors.Secondary }]}>MCX</Text>
                        </Pressable>
                    </View>
                </View>
                <View style={[styles.topView, { borderColor: colors.Secondary, }]}>
                    <View style={styles.indexView}>
                        <Text style={[styles.title, { color: colors.Secondary, }]}>Nifty</Text>
                        <View style={styles.indexValueView}>
                            <Text style={[styles.indexFirstValue, { color: colors.Secondary, }]}>{niftyPosition.current}</Text>
                            {
                                niftyPosition.current &&
                                <>
                                    {
                                        niftyPoints.current > 0 ?
                                            <Image source={UpIcon} style={styles.icon} />
                                            :
                                            <Image source={DownIcon} style={styles.icon} />
                                    }
                                </>
                            }
                            <Text style={[styles.indexSecondValue, { color: colors.Secondary, }]}>{niftyPoints.current && niftyPoints.current.toFixed(2)}</Text>
                        </View>
                    </View>
                    <View style={[styles.verticleLine, { backgroundColor: colors.Secondary, }]}></View>
                    <View style={styles.indexView}>
                        <Text style={[styles.title, { color: colors.Secondary, }]}>Sensex</Text>
                        <View style={styles.indexValueView}>
                            <Text style={[styles.indexFirstValue, { color: colors.Secondary, }]}>{sensexPosition.current}</Text>
                            {
                                sensexPoints.current &&
                                <>
                                    {
                                        sensexPoints.current > 0 ?
                                            <Image source={UpIcon} style={styles.icon} />
                                            :
                                            <Image source={DownIcon} style={styles.icon} />
                                    }
                                </>
                            }
                            <Text style={[styles.indexSecondValue, { color: colors.Secondary, }]}>{sensexPoints.current && sensexPoints.current.toFixed(2)}</Text>
                        </View>
                    </View>
                </View>
                <TextTicker
                    style={{ fontSize: 12, color: '#FF0000', marginTop: 5 }}
                    duration={10000}
                    loop
                    bounce
                    repeatSpacer={50}
                    marqueeDelay={1000}
                    shouldAnimateTreshold={80}
                >
                    {topMessage}
                </TextTicker>
                {
                    isEdit &&
                    <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginRight: 20, marginTop: 10, marginBottom: 10 }}>
                        <Pressable onPress={() => setIsEdit(false)}>
                            <Image source={cancelIcon} style={{ height: 25, width: 25, marginRight: 10 }} />
                        </Pressable>
                        <Pressable onPress={() => onDeleteScript()}>
                            <Image source={deleteIcon} style={{ height: 25, width: 25 }} />
                        </Pressable>
                    </View>
                }
                {isLoading && liveData.length == 0 && <View style={styles.loaderView}>
                    <ActivityIndicator size="small" color='#000' />
                </View>}
                {!isLoading && liveData.length == 0 && <View style={styles.loaderView}>
                    <Text style={[styles.errorMessage, { color: colors.Secondary, }]}>No Scripts Found!</Text>
                </View>}
                {!isLoading && liveData.length > 0 &&
                    <View style={{ height: Height * 0.7 }}>
                        <FlatList
                            data={liveData}
                            renderItem={({ item, index }) =>
                                <View style={{ flexDirection: 'row', marginLeft: 10 }} key={new Date().getTime()}>
                                    {
                                        isEdit &&
                                        <View style={{ marginTop: 30 }}>
                                            <Checkbox
                                                disabled={false}
                                                value={item.isChecked}
                                                onValueChange={(newValue) => {
                                                    liveData[index].isChecked = !liveData[index].isChecked
                                                    setLiveData([...liveData])
                                                }}
                                            />
                                        </View>
                                    }
                                    <Pressable style={[styles.item, { width: isEdit ? '90%' : '95%', backgroundColor: item.ba ? 'orange' : 'white' }]} onPress={() => { isEdit == false && refRBSheet.current.open(), selectedIndex.current = index, setQuantity(item.lot_size.toString()), setLot('1'), setPrice(''), setMarketType('MARKET') }}>
                                        <View>
                                            <Text style={{ color: colors.Secondary, marginBottom: 2 }}>{item.expiry && moment(item.expiry).format('DDMMMYY')} {item.expire_new && `(${moment(item.expire_new).format('DDMMM')})`}</Text>
                                            <Text style={[styles.tradeName, { color: colors.Secondary, }]}>{item.name}</Text>
                                            <View style={{ padding: 3, borderRadius: 5, marginBottom: 2 }}>
                                                <Text style={{ color: item.n > 0 ? 'green' : 'red', fontWeight: '700' }}>{item.n && item.n.toFixed(2)} <Text style={{ color: item.ch > 0 ? 'green' : 'red' }}>({item.ch && item.ch.toFixed(2)}%)</Text></Text>
                                            </View>
                                        </View>
                                        <View>
                                            <Text style={[styles.rate, { color: colors.Secondary, }]}>L: {item.l}</Text>
                                            <View style={{ backgroundColor: item.bc == 'black' ? 'white' : item.bc, borderRadius: 5, alignItems: 'center', width: Width * 0.23 }}>
                                                <Text style={[styles.price, { color: (item.bc == 'black' && item.b) ? 'black' : (item.bc && item.b) ? 'white' : 'black' }]}>{item.b}</Text>
                                            </View>
                                            <Text style={[styles.rate, { marginTop: 2 }]}>Qty: <Text style={{ color: (Number(item.total_buy_quantity) - Number(item.total_sell_quantity) > 0 ? 'green' : (Number(item.total_buy_quantity) - Number(item.total_sell_quantity)) == 0 ? 'black' : 'red') }}>{Number(item.total_buy_quantity) - Number(item.total_sell_quantity)}</Text></Text>
                                        </View>
                                        <View>
                                            <Text style={[styles.rate, { color: colors.Secondary, }]}>H: {item.h}</Text>
                                            <View style={{ backgroundColor: item.ac == 'black' ? 'white' : item.ac, borderRadius: 5, alignItems: 'center', width: Width * 0.23 }}>
                                                <Text style={[styles.price, { color: (item.ac == 'black' && item.a) ? 'black' : (item.ac && item.a) ? 'white' : 'black' }]}>{item.a}</Text>
                                            </View>
                                            <Text style={[styles.rate, { color: colors.Secondary, marginTop: 2 }]}>LTP: {item.ltp}</Text>
                                        </View>
                                    </Pressable>
                                </View>}
                            keyExtractor={item => item.id}
                            refreshControl={
                                <RefreshControl refreshing={isLoading} enabled={true} onRefresh={() => getData(selectedSegment)} />
                            }
                        />
                    </View>
                }

            </View>
            <RBSheet
                ref={refRBSheet}
                closeOnDragDown={true}
                closeOnPressMask={true}
                height={305}
                customStyles={{
                    wrapper: {
                        backgroundColor: "transparent",
                    },
                    draggableIcon: {
                        backgroundColor: "#000",
                    },
                    container: {
                        borderTopRightRadius: 20,
                        borderTopLeftRadius: 20,
                        backgroundColor: '#c97fc7'
                    }
                }}
            >
                <View style={{ marginLeft: 10, flexDirection: 'row', justifyContent: 'space-between', marginRight: 20 }}>
                    <View>
                        <Text style={{ color: colors.Secondary, fontSize: 22, fontWeight: '700' }}>{liveData[selectedIndex.current]?.name}</Text>
                        <Text style={{ color: colors.Secondary, fontWeight: '400' }}>{moment(liveData[selectedIndex.current]?.expiry).format('DDMMMYYYY')}</Text>
                    </View>
                    <View style={{ padding: 5 }}>
                        <Text style={{ color: colors.Secondary }}>L: {liveData[selectedIndex.current]?.l}</Text>
                        <Text style={{ color: colors.Secondary }}>O: {liveData[selectedIndex.current]?.o}</Text>
                    </View>
                    <View style={{ padding: 5 }}>

                        <Text style={{ color: colors.Secondary }}>H: {liveData[selectedIndex.current]?.h}</Text>
                        <Text style={{ color: colors.Secondary }}>C: {liveData[selectedIndex.current]?.c}</Text>

                    </View>
                </View>
                <View>
                    <View style={{ borderRadius: 5, alignItems: 'center' }}>
                        <Text style={{ color: colors.Secondary, fontWeight: '700', fontSize: 20 }}>LTP: {liveData[selectedIndex.current]?.ltp}</Text>
                    </View>
                </View>
                <View style={{ marginLeft: 10, flexDirection: 'row', justifyContent: 'space-between', marginRight: 20, marginTop: 8 }}>
                    <Pressable style={{ borderWidth: 1, padding: 5, borderRadius: 5, width: '20%', alignItems: 'center', backgroundColor: marketType == 'MARKET' ? '#8d448b' : '#c97fc7' }} onPress={() => setMarketType('MARKET')}>
                        <Text style={{ color: marketType == 'MARKET' ? 'white' : 'black', fontWeight: '500' }}>Market</Text>
                    </Pressable>
                    <Pressable style={{ borderWidth: 1, padding: 5, borderRadius: 5, width: '20%', alignItems: 'center', backgroundColor: marketType == 'LIMIT' ? '#8d448b' : '#c97fc7' }} onPress={() => setMarketType('LIMIT')}>
                        <Text style={{ color: marketType == 'LIMIT' ? 'white' : 'black', fontWeight: '500' }}>Limit</Text>
                    </Pressable>
                    <Pressable style={{ borderWidth: 1, padding: 5, borderRadius: 5, width: '20%', alignItems: 'center', backgroundColor: marketType == 'STOPLOSS' ? '#8d448b' : '#c97fc7' }} onPress={() => setMarketType('STOPLOSS')}>
                        <Text style={{ color: marketType == 'STOPLOSS' ? 'white' : 'black', fontWeight: '500' }}>SL</Text>
                    </Pressable>
                    <Pressable style={{ borderWidth: 1, padding: 5, borderRadius: 5, width: '20%', alignItems: 'center', backgroundColor: marketType == 'INTRADAY' ? '#8d448b' : '#c97fc7' }} onPress={() => setMarketType('INTRADAY')}>
                        <Text style={{ color: marketType == 'INTRADAY' ? 'white' : 'black', fontWeight: '500' }}>Intraday</Text>
                    </Pressable>
                </View>
                {
                    marketType != 'INTRADAY' &&
                    <View style={{ flexDirection: 'row', marginLeft: 10, marginRight: 20, marginTop: 10, justifyContent: 'space-evenly' }}>

                        <View style={{ width: '31%' }}>
                            <Text style={{ color: colors.Secondary, marginBottom: 2, fontWeight: '400', textAlign: 'center' }}>Lot</Text>
                            <TextInput keyboardType='numeric' style={{ borderWidth: 1, width: '100%', borderRadius: 10, borderColor: 'grey', height: 40, textAlign: 'center', color: colors.Secondary, fontSize: 18, fontWeight: 'bold' }} value={lot} onChangeText={text => onChangingLot(text.replace(/[^0-9]/g, ''))} />
                        </View>
                        <View style={{ width: '31%' }}>
                            <Text style={{ color: colors.Secondary, marginBottom: 2, fontWeight: '400', textAlign: 'center' }}>Quatity</Text>
                            <TextInput keyboardType='numeric' style={{ borderWidth: 1, width: '100%', borderRadius: 10, borderColor: 'grey', height: 40, textAlign: 'center', color: colors.Secondary, fontSize: 18, fontWeight: 'bold' }} editable={selectedSegment == 'NFO' ? true : false} value={quantity} onChangeText={text => onChangingQuantity(text)} />
                        </View>
                        {
                            (marketType == 'LIMIT' || marketType == 'STOPLOSS') &&
                            <View style={{ width: '31%' }}>
                                <Text style={{ color: colors.Secondary, marginBottom: 2, fontWeight: '400' }}>Price</Text>
                                <TextInput keyboardType='numeric' style={{ borderWidth: 1, width: '100%', borderRadius: 10, borderColor: 'grey', height: 40, color: colors.Secondary, textAlign: 'center', fontSize: 18, fontWeight: 'bold' }} value={price} onChangeText={text => {
                                    setPrice(text)
                                    var data = (text / liveData[selectedIndex.current].tick_size).toFixed(2) % 1 == 0
                                    {
                                        data == false &&
                                            Toast.show({
                                                type: 'error',
                                                text1: `Price Should be in multiple of ${liveData[selectedIndex.current]?.tick_size}`,
                                            });
                                    }
                                }} />
                            </View>
                        }
                    </View>
                }
                <View style={{ flexDirection: 'row', marginLeft: 10, marginRight: 20, marginTop: 10, justifyContent: 'space-evenly' }}>

                </View>
                {
                    marketType != 'INTRADAY' &&
                    <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', marginTop: 8 }} >

                        <Pressable style={{ backgroundColor: '#FF033E', width: '40%', height: 60, borderRadius: 10, justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}
                            disabled={buttonLoader}
                            onPress={() => {
                                if (checkShowModal) {
                                    selectedTrade.current = 'SELL'
                                    Keyboard.dismiss()
                                    refRBSheet.current.close()
                                    onSubmit('SELL')
                                } else {
                                    selectedTrade.current = 'SELL'
                                    Keyboard.dismiss()
                                    refRBSheet.current.close()
                                    setModalVisible('true')
                                }
                            }}>
                            {buttonLoader && <ActivityIndicator size="small" color="#000" style={{ marginRight: 20 }} />}
                            {
                                (marketType == 'LIMIT' || marketType == 'STOPLOSS') ?
                                    <Text style={{ color: 'white', fontWeight: '700', fontSize: 15 }}>SELL</Text>
                                    :
                                    <Text style={{ color: 'white', fontWeight: '700', fontSize: 15 }}>SELL @ {liveData[selectedIndex.current]?.b}</Text>
                            }
                        </Pressable>
                        <Pressable style={{ backgroundColor: '#1034A6', width: '40%', height: 60, borderRadius: 10, justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}
                            disabled={buttonLoader}
                            onPress={() => {
                                if (checkShowModal) {
                                    selectedTrade.current = 'BUY'
                                    Keyboard.dismiss()
                                    refRBSheet.current.close()
                                    onSubmit('BUY')
                                } else {
                                    selectedTrade.current = 'BUY'
                                    Keyboard.dismiss()
                                    refRBSheet.current.close()
                                    setModalVisible('true')
                                }

                            }}>
                            {buttonLoader && <ActivityIndicator size="small" color="#000" style={{ marginRight: 20 }} />}
                            {
                                (marketType == 'LIMIT' || marketType == 'STOPLOSS') ?
                                    <Text style={{ color: 'white', fontWeight: '700', fontSize: 15 }}>BUY</Text>
                                    :
                                    <Text style={{ color: 'white', fontWeight: '700', fontSize: 15 }}>BUY @ {liveData[selectedIndex.current]?.a}</Text>

                            }
                        </Pressable>
                    </View>
                }
                {
                    marketType == 'INTRADAY' &&
                    <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', marginTop: '10%' }} >
                        <Pressable style={{ backgroundColor: '#1034A6', width: '40%', height: 60, borderRadius: 10, justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}
                            disabled={buttonLoader}
                            onPress={() => {
                                if (checkShowModal) {
                                    selectedTrade.current = 'BUY'
                                    Keyboard.dismiss()
                                    onSubmit('BUY')
                                } else {
                                    selectedTrade.current = 'BUY'
                                    Keyboard.dismiss()
                                    refRBSheet.current.close()
                                    setModalVisible('true')
                                }
                            }}>
                            {buttonLoader && <ActivityIndicator size="small" color="white" style={{ marginRight: 20 }} />}
                            <Text style={{ color: 'white', fontWeight: '700', fontSize: 15 }}>Submit</Text>
                        </Pressable>
                    </View>
                }
            </RBSheet>
        </>
    )
}

export default MarketWatch;

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    header: {
        paddingLeft: 25,
        marginTop: 10,
        borderBottomWidth: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingRight: 15,
        height: 40,
    },
    topView: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: 45,
        borderBottomWidth: 1,
        borderTopWidth: 1,
        paddingTop: 8,
        backgroundColor: '#c97fc7'
    },
    verticleLine: {
        height: '100%',
        width: 1,
    },
    indexView: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '50%',
    },
    title: {
        fontSize: 14,
        textTransform: 'uppercase'
    },
    indexValueView: {
        flexDirection: 'row',
        marginTop: 1
    },
    indexFirstValue: {
        fontSize: 14
    },
    indexSecondValue: {
        fontSize: 12,
        marginLeft: 5,
        lineHeight: 20
    },
    icon: {
        bottom: 5,
        height: 20,
        width: 20
    },
    errorMessage: {
        textAlign: 'center',
        marginTop: 20
    },
    headerIcon: {
        flexDirection: 'row'
    },
    searchIcon: {
        // bottom: 5
        height: 32,
        width: 32
    },
    addIcon: {
        height: 30,
        width: 30,
        marginRight: 10,
    },
    dropdownView: {
        width: '60%',
        bottom: 10
    },
    segmentTabView: {
        flexDirection: 'row',
        marginLeft: 22,
        marginRight: 22,
        marginTop: 10,
    },
    tabText: {
        fontWeight: '700'
    },
    segementTab: {
        width: '50%',
        alignItems: 'center',
        borderWidth: 1,

        padding: 3,
        borderRadius: 5,
        marginLeft: 5
    },
    modalView: {
        margin: 10,
        // padding: 30,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        bottom: 80,
    },
    modalText: {
        fontSize: 16,
        textAlign: 'center',
        color: 'white',
        padding: 10,
        backgroundColor: 'transparent',
        marginLeft: 29,
        marginRight: 29
    },
    modalLightText: {
        fontSize: 16,
        textAlign: 'center',
        padding: 10,
        backgroundColor: 'transparent',
        marginLeft: 29,
        marginRight: 29,
        color: 'white'
    },
    modalOrderText: {
        fontSize: 16,
        textAlign: 'center',
        color: 'white',
        padding: 10,
        backgroundColor: 'transparent',
        marginLeft: 40,
        marginRight: 40,
    },
    modalOrderLightText: {
        fontSize: 16,
        textAlign: 'center',
        padding: 10,
        backgroundColor: 'transparent',
        marginLeft: 40,
        marginRight: 40,
        color: 'white'
    },
    modalButton: {
        borderRadius: 20,
        marginTop: 5.5,
        marginBottom: 5.5,
        marginLeft: 40,
        marginRight: 40,
        backgroundColor: 'red'
    },
    modalLightButton: {
        borderRadius: 20,
        marginTop: 5.5,
        marginBottom: 5.5,
        marginLeft: 40,
        marginRight: 40,
        backgroundColor: 'black'
    },
    cancelButton: {
        borderRadius: 5,
        borderWidth: 1,
        marginTop: 20
    },
    cancelText: {
        fontSize: 16,
        textAlign: 'center',
        padding: 8,
        backgroundColor: 'transparent',
        marginLeft: 30,
        marginRight: 30
    },
    continueButton: {
        borderRadius: 5,
        marginTop: 20
    },
    continueText: {
        fontSize: 16,
        textAlign: 'center',
        color: 'white',
        padding: 8,
        backgroundColor: 'transparent',
        marginLeft: 30,
        marginRight: 30
    },
    item: {
        borderBottomWidth: 1,
        borderColor: '#707070',
        marginLeft: 5,
        marginRight: 5,
        marginTop: 5,
        borderRadius: 5,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    tradeName: {
        fontSize: 18,
        fontWeight: '700'
    },
    tradeQty: {
        color: 'green',
        fontSize: 18
    },
    rate: {
        // marginBottom: 2
    },
    price: {
        color: 'white',
        fontSize: 18,
        // marginBottom: 2,
        fontWeight: '700'
    },
    toogleView: {
        flexDirection: 'row',
        marginLeft: 12,
        marginTop: 10
    },
    toogle: {
        height: 24,
        width: 24,
        borderRadius: 12,
        borderWidth: 2,
        borderColor: 'black',
        alignItems: 'center',
        justifyContent: 'center',
    },
    toogleFill: {
        height: 12,
        width: 12,
        borderRadius: 6,
        backgroundColor: 'blue',
    },
    toogleText: {
        color: 'black',
        fontSize: 14,
        marginLeft: 10,
        marginRight: 50
    },
    input: {
        borderRadius: 12,
        width: Width * 0.5,
        paddingLeft: 15,
        bottom: 6
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 22,
        backgroundColor: 'rgba(0,0,0,0.5)'
    },
    modalView: {
        margin: 20,
        backgroundColor: 'white',
        borderRadius: 20,
        padding: 10,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 500,
    },
})