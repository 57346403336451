import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, View, Text, Image, Pressable, TextInput, FlatList, ActivityIndicator } from 'react-native';
import { useTheme } from '../../Contexts/ThemeProvider';
import backIcon from '../../Assets/Images/backArrow.png';
import backBlackIcon from '../../Assets/Images/back-arrow-black.png';
import axios from 'axios';
import { BASE_URL } from '../../Config';
import { Height } from '../../Utility/Utility';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { resetAuth } from '../../Actions/AuthActions';
import { useDispatch } from 'react-redux';

const QuantityReport = (props) => {
    const dispatch = useDispatch()
    const { dark, colors } = useTheme();
    const _onBack = () => props.navigation.goBack()
    const [reportData, setReportData] = useState([])
    const userId = useRef('')
    const [selectedSegment, setSelectedSegment] = useState('NFO')
    const [isLoading, setLoading] = useState(false)

    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        try {
            const jsonValue = await AsyncStorage.getItem('user_detail')
            const data = jsonValue != null ? JSON.parse(jsonValue) : null;
            userId.current = data.id
            checkTab()
        } catch (e) {
            // error reading value
        }
    }

    const checkTab = async () => {
        await AsyncStorage.getItem('tab')
            .then(req => JSON.parse(req))
            .then(data => {
                if (data != null) {
                    setSelectedSegment(data.currenTab)
                    getReport(data.currenTab)
                } else {
                    getReport('NFO')
                }
            })
            .catch(error => {
                getReport('NFO')
            });
    }

    const getReport = (value) => {
        setLoading(true)
        axios.get(`${BASE_URL}/quantity-setting-list?exchange=${value}&user_id=${userId.current}`)
            .then((res) => {
                setLoading(false)
                setReportData(res.data)
            })
            .catch((e) => {
                setLoading(false)
                if (e.response.status == 401) {
                    dispatch(resetAuth())
                }
            })
    }

    const onChangeTab = async (value) => {
        const data = JSON.stringify({ currenTab: value })
        await AsyncStorage.setItem('tab', data)
        setReportData([])
        setSelectedSegment(value)
        getReport(value)
    }

    return (
        <>
            <View style={[styles.container, { backgroundColor: colors.Primary }]}>
                <View style={[styles.header, { borderColor: colors.Secondary }]}>
                    <View>
                        <Pressable onPress={() => _onBack()}>
                            <Image source={dark ? backIcon : backBlackIcon} style={{ marginTop: 5, height: 18, width: 18 }} />
                        </Pressable>
                    </View>
                    <View style={styles.titleView}>
                        <Text style={[styles.headerTitle, { color: colors.Secondary }]}>Quantity Setting</Text>
                    </View>
                </View>
                <View style={{ backgroundColor: '#c97fc7', paddingBottom: 10 }}>
                    <View style={styles.segmentTabView}>
                        <Pressable style={[styles.segementTab, { backgroundColor: selectedSegment == 'NFO' ? '#8d448b' : colors.Primary, borderColor: colors.Secondary }]} onPress={() => { onChangeTab('NFO') }}>
                            <Text style={[styles.tabText, { color: selectedSegment == 'NFO' ? colors.Primary : colors.Secondary }]}>NSE</Text>
                        </Pressable>
                        <Pressable style={[styles.segementTab, { backgroundColor: selectedSegment == 'MCX' ? '#8d448b' : colors.Primary, borderColor: colors.Secondary }]} onPress={() => { onChangeTab('MCX') }}>
                            <Text style={[styles.tabText, { color: selectedSegment == 'MCX' ? colors.Primary : colors.Secondary }]}>MCX</Text>
                        </Pressable>
                    </View>
                </View>
                {/* <View style={{ marginTop: 30, marginLeft: 5, marginRight: 10, height: reportData.length > 0 ? Height * 0.88 : Height * 0.1  }}>
                    <View style={{ width: '100%', flexDirection: 'row', borderWidth: 1, borderColor: colors.Secondary }}>
                        <Text style={{ fontSize: 10, fontWeight: '700', width: '15%', textAlign: 'center', paddingTop: 10, paddingBottom: 10, color: colors.Secondary }}>Symbol</Text>
                        <View style={[styles.verticalLine, { borderColor: colors.Secondary }]} />
                        <Text style={{ fontSize: 10, fontWeight: '700', width: '15%', textAlign: 'center', paddingTop: 10, paddingBottom: 10, color: colors.Secondary }}>Rate Start</Text>
                        <View style={[styles.verticalLine, { borderColor: colors.Secondary }]} />
                        <Text style={{ fontSize: 10, fontWeight: '700', width: '15%', textAlign: 'center', paddingTop: 10, paddingBottom: 10, color: colors.Secondary }}>Rate End</Text>
                        <View style={[styles.verticalLine, { borderColor: colors.Secondary }]} />
                        <Text style={{ fontSize: 10, fontWeight: '700', width: '10%', textAlign: 'center', paddingTop: 10, paddingBottom: 10, color: colors.Secondary }}>Lot / Qty</Text>
                        <View style={[styles.verticalLine, { borderColor: colors.Secondary }]} />
                        <Text style={{ fontSize: 10, fontWeight: '700', width: '10%', textAlign: 'center', paddingTop: 10, paddingBottom: 10, color: colors.Secondary }}>Position Limit</Text>
                        <View style={[styles.verticalLine, { borderColor: colors.Secondary }]} />
                        <Text style={{ fontSize: 10, fontWeight: '700', width: '10%', textAlign: 'center', paddingTop: 10, paddingBottom: 10, color: colors.Secondary }}>Max Order</Text>
                        <View style={[styles.verticalLine, { borderColor: colors.Secondary }]} />
                        <Text style={{ fontSize: 10, fontWeight: '700', width: '10%', textAlign: 'center', paddingTop: 10, paddingBottom: 10, color: colors.Secondary }}>Min Order</Text>
                        <View style={[styles.verticalLine, { borderColor: colors.Secondary }]} />
                        <Text style={{ fontSize: 10, fontWeight: '700', width: '10%', textAlign: 'center', paddingTop: 10, paddingBottom: 10, color: colors.Secondary }}>Add Time</Text>
                    </View>
                    {
                        reportData.length > 0 && <FlatList
                            data={reportData}
                            renderItem={({ item }) => <View style={{ flexDirection: 'row', width: '100%' }}>
                                <Text style={{ fontSize: 12, fontWeight: '700', textAlign: 'center', width: '15.2%', paddingTop: 5, paddingBottom: 5, borderColor: colors.Secondary, borderBottomWidth: 1, color: colors.Secondary, borderLeftWidth: 1 }}>{item.instrument_token}</Text>
                                <View style={styles.verticalLine} />
                                <Text style={{ fontSize: 11, fontWeight: '700', textAlign: 'center', width: '14.9%', paddingTop: 5, paddingBottom: 5, color: colors.Secondary, borderColor: colors.Secondary, borderBottomWidth: 1 }}></Text>
                                <View style={styles.verticalLine} />
                                <Text style={{ fontSize: 11, fontWeight: '700', textAlign: 'center', width: '15%', paddingTop: 5, paddingBottom: 0, color: colors.Secondary, borderColor: colors.Secondary, borderBottomWidth: 1 }}></Text>
                                <View style={styles.verticalLine} />
                                <Text style={{ fontSize: 12, fontWeight: '700', textAlign: 'center', width: '10%', paddingTop: 5, paddingBottom: 0, color: colors.Secondary, borderColor: colors.Secondary, borderBottomWidth: 1 }}>{item.type}</Text>
                                <View style={styles.verticalLine} />
                                <Text style={{ fontSize: 12, fontWeight: '700', textAlign: 'center', width: '10%', paddingTop: 5, paddingBottom: 0, color: colors.Secondary, borderColor: colors.Secondary, borderBottomWidth: 1 }}>{item.position_limit}</Text>
                                <View style={styles.verticalLine} />
                                <Text style={{ fontSize: 12, fontWeight: '700', textAlign: 'center', width: '10%', paddingTop: 5, paddingBottom: 0, color: colors.Secondary, borderColor: colors.Secondary, borderBottomWidth: 1 }}>{item.max_order}</Text>
                                <View style={styles.verticalLine} />
                                <Text style={{ fontSize: 12, fontWeight: '700', textAlign: 'center', width: '10%', paddingTop: 5, paddingBottom: 0, color: colors.Secondary, borderColor: colors.Secondary, borderBottomWidth: 1 }}>{item.min_order}</Text>
                                <View style={styles.verticalLine} />
                                <Text style={{ fontSize: 12, fontWeight: '700', textAlign: 'center', width: '13%', paddingTop: 5, paddingBottom: 0, color: colors.Secondary, borderColor: colors.Secondary, borderBottomWidth: 1, borderRightWidth: 1 }}>{item.min_order}</Text>
                            </View>}
                            keyExtractor={item => item.id}
                        />
                    }
                </View> */}
                {
                    reportData.length > 0 && <FlatList
                        data={reportData}
                        renderItem={({ item }) => <View style={{ padding: 10, borderBottomWidth: 1, borderBottomColor: '#D3D3D3' }} key={new Date()}>
                            <View style={{ flexDirection: 'row' }}>
                                <Text style={{ fontWeight: 'bold', width: '50%' }}>{item.instrument_token}</Text>
                                <View style={{ flexDirection: 'row', width: '50%', justifyContent: 'space-between' }}>
                                    <Text style={{ fontWeight: '500' }}>Max: {item.max_order}</Text>
                                    <Text style={{ fontWeight: '500' }}>{item.position_limit}</Text>
                                </View>
                            </View>
                        </View>}
                        keyExtractor={item => item.id}
                    />
                }
                {isLoading && reportData.length == 0 && <View style={styles.loaderView}>
                    <ActivityIndicator size="small" color='#000' />
                </View>}
                {!isLoading && reportData.length == 0 && <View style={styles.loaderView}>
                    <Text style={{ color: colors.P }}>No Quantity Setting Found!</Text>
                </View>}
            </View>
        </>
    )
}

export default QuantityReport;

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    header: {
        paddingLeft: 25,
        marginTop: 10,
        borderBottomWidth: 1,
        flexDirection: 'row',
        paddingRight: 25,
        height: 40
    },
    titleView: {
        alignItems: 'center',
        width: '90%'
    },
    headerTitle: {
        fontSize: 16,
    },
    verticalLine: {
        borderRightWidth: 1
    },
    segmentTabView: {
        flexDirection: 'row',
        marginLeft: 22,
        marginRight: 22,
        marginTop: 10,
    },
    segementTab: {
        width: '50%',
        alignItems: 'center',
        borderWidth: 1,
        padding: 3,
        borderRadius: 5,
        marginLeft: 5
    },
    loaderView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
})